












  import { Component, Prop, Vue } from 'nuxt-property-decorator'
  import { namespace } from 'vuex-class'
  import { Watch } from 'vue-property-decorator'

  const layout = namespace('layout')
  @Component({
    components: {
      OneButtonToggle: () => import('~/components/molecules/buttons/OneButtonToggle.vue'),
    },
  })
  export default class OneTaxSwitcherComponent extends Vue {
    @layout.Mutation('SET_PRICE_GROSS') setPriceGross: any
    @layout.Mutation('SET_SHOULD_SET_GROSS_AS_DEFAULT_VALUE') setShouldSetGrossAsDefaultValue: any
    @layout.State(state => state.priceGross) isPriceGross!: boolean

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    readonly showOnlyForB2B!: boolean

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    readonly isGrossDefaultValue!: boolean

    @Watch('$auth.isAuthenticated')
    updateDefaultPriceValue() {
      if (this.shouldSetGrossAsDefaultValue()) {
        this.setPriceGross(this.isGrossDefaultValue)
      }
      this.setShouldSetGrossAsDefaultValue(this.shouldSetGrossAsDefaultValue())
    }

    created() {
      this.updateDefaultPriceValue()
    }

    get shouldShowSwitcher() {
      if (this.$utils.isPurchaseEnabledOrAuthenticated) {
        return this.showOnlyForB2B ? this.$utils.isB2B : true
      }
      return false
    }

    shouldSetGrossAsDefaultValue() {
      return this.isGrossDefaultValue &&
        (this.$utils.isB2B || this.$auth.isAuthenticated)
    }
  }
